@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap");
.main {
    display: flex;
    position: relative;
}
.left {
    width: 50%;
    font-family: "Space Grotesk", sans-serif;
    color: #d1000a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 2%;
}
.left h1 {
    font-size: 56px !important;
    font-weight: 400;
    letter-spacing: -0.04em;
}
.leftParas p {
    font-size: 20px;
    font-weight: 500;
    margin-top: 3rem;
}
.formHeading {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    color: #d1000a;
    font-weight: 600;
}
.right {
    display: grid;
    background-color: rgb(232, 230, 230);
    width: 50%;
    grid-template-columns: 1fr;
}
.form {
    margin-bottom: -20rem;
}
.image {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}
.image img {
    width: 320px;
    height: 441px;
    object-fit: cover;
    margin-left: 3rem;
    margin-top: 2.5rem;
    left: 1rem;
}
.right form {
    position: relative;
    margin: 0 auto;
    width: 55%;
    margin: 0 auto;
    background-color: white;
    grid-area: 1 / 1 / 2 / 2;
    left: 9%;
    bottom: 45%;
    justify-self: start;
    align-self: start;
    padding: 5% 2%;
}
.checkPara span {
    font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
}
.buttonDiv {
    width: 100%;
    text-align: center;
}
.buttonDiv button {
    width: 80%;
    margin-top: 2rem;
    border: none;
    background-color: #d1000a;
    color: white;
    padding: 3% 4%;
}
.buttonDiv button:hover {
    background-color: white;
    color: #d1000a;
    border: 1px solid #d1000a;
}
.buttonsGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.Button {
    width: 5rem;
    padding: 0.3rem;
    border: none;
}
.activeSignUp {
    width: 5rem;
    padding: 0.3rem;
    background-color: #d1000a;
    outline: none;
    color: white;
    border: none;
}
.dropDownDiv {
    margin: 0 auto!important;
    width: 85% !important;
    left: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

@media(max-width:1000px)
{
    .right form{
        left: 15%;
        width: 57%;
    }
}

@media(max-width: 800px)
{
    .image {
        display: none;
    }
    .right form{
        width: 100%;
        position: static;
    }
    .right .form{
        margin: 0;
    }
    .left h1{
        font-size: 30px !important;
    }
}

@media(max-width:600px)
{
    .main{
        flex-direction: column;
        padding: 2rem;
    }
    .right{
        width: 100%;
    }
    .left{
        width: 100%;
    }
}