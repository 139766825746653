@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500&display=swap");
.main {
    background-color: rgb(232, 230, 230);
}
.upper {

    height: auto;
    width: 100%;
    background-image: url("./bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5% 4%;
}
.image {
    display: flex;
    position: relative;
    width: 100%;
}
.image img {
    width: 60%;
    height: 63%;
    object-fit: cover;
}
.contentDiv {
    position: relative;
    right: 0;
    display: flex;
}
.content {
    width: 38rem;
    background-color: rgba(255, 255, 255, 0.319);
    justify-self: end;
    padding: 5% 4%;
    border-radius: 9px;
    margin: 0 auto;
}
.upper h1 {
    font-size: 56px;
    color: #d1000a;
    margin: 0;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 400;
}
.content p {
    line-height: 1.8em;
    color: #d1000a;
    font-family: "Poppins", sans-serif;
}
.desDiv {
    margin: 0 auto;
    padding: 1rem 2rem;
}
.desDiv p {
    color: #d1000a;
}
.lower {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0.5rem 2rem;
    gap: 1rem;
    max-width: 100vw;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
}
.cardLink {
    text-decoration: none;
}
.card {
    background-color: white;
    padding: 3rem 5px;
    margin-bottom: 1rem;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.303);
    cursor: pointer;
}
.card hr {
    width: 90%;
    margin: 10px auto;
    border: none;
    height: 1.5px;
    color: black !important;
    background-color: black !important;
}
.card h4 {
    text-align: center;
    color: #d1000a;
    margin-bottom: 0;
}
.card img {
    width: 400px;
    margin-top: 1rem;
}
.imageDiv {
    text-align: center;
}

.buttonDiv {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    gap: 20px;
}
.buttonDiv label {
    font-weight: 700;
    color: #f6f9f5;
}
.buttonDiv button {
    width: 50%;
    outline: none;
    border: none;
    border-radius: 4px;
    height: 48px;
    font-weight: 700;
    color: #386876;
}

@media (max-width: 1285px) {
    .lower {
        grid-template-columns: 1fr 1fr;
    }
    .card img {
        width: 80%;
    }
}

@media (max-width: 800px) {
    .lower {
        grid-template-columns: 1fr;
    }
    .card img {
        width: 80%;
    }
}
