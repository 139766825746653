.main {
    display: flex;
    padding: 1rem;
}

.filter {
    width: 23%;
    padding: 20px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    height: 90vh;
    overflow-y: auto;
    border: 1px solid rgba(112, 128, 144, 0.238);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.category h4 {
    margin: 1rem 0;
}
.location h4 {
    margin: 1rem 0;
}
.location input {
    padding: 1rem;
    width: 100%;
}
.submitDiv button {
    width: 100%;
    border: none;
    padding: 1rem;
    border-radius: 7px;
    background-color: #d1000a;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.results {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
