.main {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.wrapper p{
    text-align: center;
    font-weight: 500;
    font-size: larger;
}