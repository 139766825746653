.rating-stars {
    display: inline-block;
}
.star {
    font-size: 24px;
    color: #ccc;
    margin-right: 5px;
}
.star.filled {
    color: gold;
}
