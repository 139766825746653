@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@700&family=Space+Grotesk&display=swap");

.container {
    gap: 2rem !important;
    text-align: center;
}
.navToggler {
    border: none !important;
}
.navToggler:focus,
.navToggler:active {
    outline: 10px solid #f8f9fa !important;
}
.close button:focus,
.close button:active {
    outline: 5px solid #fff !important;
}
.nav {
    padding: 1.2rem 1.3rem !important;
    background-color: transparent;
    margin: 0 !important;
}
.close {
    text-align: center;
}
.navLink {
    padding: 0.5rem 1rem;
    color: #d1000a !important;
    font-family: "Poppins", sans-serif !important;
    border-radius: 50px;
    width: 5.7rem;
}
.userIcon {
    color: #d1000a !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
    width: 5rem;
    font-size: large;
    height: 100%;
    background-color: transparent !important;
    box-shadow: none !important;
    display: flex;
    gap:10px;
}
.navLink:global(.active) {
    border-radius: 50px;
    background-color: #d1000a;
    color: #fff !important;
}
.navLink:hover {
    background-color: #d1000a;
    color: white !important;
}
.logoutButton {
    all: unset;
    border: none;
    background-color: none;
}
.test:hover {
    background-color: #d1000a;
    color: white;
}
:global(.offcanvas-backdrop) {
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(12.8px);
    opacity: 0.7 !important;
}
.navBrand {
    font-family: "Edu NSW ACT Foundation", cursive;
    font-size: 38px !important;
    font-weight: 400 !important;
    color: #d1000a !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.navBrand img {
    width: 23px;
}
.active {
    background-color: #d1000a;
    color: #f8f9fa !important;
}

/*media quries*/
