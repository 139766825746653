@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.main {
    width: 100vw;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}
.resetPasswordCard {
    height: 70vh;
    width: 40vw;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.323);
    border-radius: 10px;
}
.resetPasswordCard h1 {
    text-align: center;
    color: #d1000a;
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
}
.resetPasswordCard p {
    text-align: center;
    margin: 0;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
}
.registerDiv a {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    font-family: "Poppins", sans-serif;
}
.main form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 2rem auto;
    margin-top: 3.4rem;
    gap: 3rem;
}
.resetPasswordForm input {
    padding: 0.7rem 1.3rem;
    border-radius: 12px;
    border: none;
    background-color: #f0f4f5;
    font-family: "Poppins", sans-serif;
}
.resetPasswordForm button {
    margin: 0 auto;
    padding: 0.7rem 1.3rem;
    background-color: #d1000a;
    border: none;
    color: white;
    font-family: "Poppins", sans-serif;
    border-radius: 9px;
}
.resetPasswordForm button:hover {
    border: 1px solid #d1000a;
    color: #d1000a;
    background-color: transparent;
}
.resetPasswordForm input:active,
.resetPasswordForm input:focus {
    border: none !important;
    outline: none;
}

@media (max-width: 1000px) {
    .resetPasswordCard {
        width: 60vw;
    }
    .main form {
        width: 60%;
    }
}
@media (max-width: 600px) {
    .resetPasswordCard {
        width: 80vw;
    }
    .main form {
        width: 70%;
    }
}
@media (max-width: 350px) {
    .resetPasswordCard {
        width: 90vw;
    }
    .main form {
        width: 70%;
    }
}
