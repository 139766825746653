@import url("https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@700&family=Space+Grotesk&display=swap");
.productGalleryContainer {
    background-color: transparent;
}
.galleryContainer {
    width: 95%;
}
.galleryImage img {
    width: 340px;
    height: 230px;
    margin: 0;
}
.main {
    margin-top: 2px;
    height: fit-content;
    width: 100vw;
    background-image: url("https://static.wixstatic.com/media/11062b_b111f266bcec4c54bb9fcd2d7d4e4a8f~mv2.jpg/v1/fill/w_1225,h_641,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_b111f266bcec4c54bb9fcd2d7d4e4a8f~mv2.jpg");
    padding-bottom: 3rem;
}

.content {
    padding-top: 8rem;
}

.content h1 {
    text-align: center;
    font-size: 70px;
    color: #D1000a;
    font-family: 'Space Grotesk', sans-serif;
}

.content .buttonDiv{
    text-align: center;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
} 
.buttonDiv button{
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    border: none;
    color:#D1000a;
    background-color: white;
    border: 1px solid slategray;

}

.buttonDiv button:hover{
    color: white;
    background-color: #D1000a;
    border:none;
}
@media(max-width:450px){
    .content h1{
        font-size: 40px;
    }
}