@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap");

.main {
    height: fit-content;
    width: 100vw;
    background-image: url("../../../../public/images/LandingBottom.jpg");
    backdrop-filter: blur(100%);
}

.title {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.title h1 {
    text-align: center;
    color: #f6f9f5;
    font-size: 70px;
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
}
.review {
    padding: 7rem 0;
    display: grid;
    gap:1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
}
.reviewCard {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    padding: 2rem 0;
    padding-left: 2rem;
    padding-right: 1rem;
}
.reviewCard2 {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    padding: 2rem 0;
    padding-left: 2rem;
    padding-right: 1rem;
}
.review3 {
    position: relative;
}
.reviewCard3 {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    padding: 2rem 0;
    padding-left: 2rem;
    padding-right: 1rem;
}
.image {
    border-radius: 100%;
    background-color: hsl(194, 29%, 52%);
}
.Img {
    text-align: center;
}
.reviewCard p,
.reviewCard2 p,
.reviewCard3 p {
    font-size: 20px;
    color: #d1000a;
    font-family: "Poppins", sans-serif;
    font-weight: 200 !important;
    margin-top: 2rem;
    margin-bottom: 3.5rem;
}
@media (max-width: 1023px) {
    .reviewCard,
    .reviewCard2,
    .reviewCard3 {
        min-height: 89%;
        width: 90%;
    }
}
@media (max-width: 780px) {
    .review {
        grid-template-columns: 1fr;
    }
    .main {
        height: fit-content;
    }
    .title h1 {
        font-size: 40px;
    }
}
