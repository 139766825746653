.datatable {
    height: 600px;
    padding: 20px;
}
.datatable .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.datatable .datatableTitle .link {
    text-decoration: none;
    color: green;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid green;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.datatable .cellWithImg {
    display: flex;
    align-items: center;
}
.datatable .cellWithImg .cellImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}
.datatable .cellWithStatus {
    padding: 5px;
    border-radius: 5px;
}
.datatable .cellWithStatus.Confirmed {
    background-color: rgba(0, 128, 0, 0.05);
    color: green;
}
.datatable .cellWithStatus.Medicated {
    background-color: rgba(0, 128, 0, 0.05);
    color: green;
}
.datatable .cellWithStatus.specialized {
    background-color: rgba(0, 128, 0, 0.05);
    color: green;
}
.datatable .cellWithStatus.Pending {
    background-color: rgba(255, 217, 0, 0.05);
    color: goldenrod;
}
.datatable .cellWithStatus.Not-Booked {
    background-color: rgba(255, 0, 0, 0.05);
    color: crimson;
}
.datatable .cellWithStatus.not-specialized {
    background-color: rgba(255, 0, 0, 0.05);
    color: crimson;
}
.datatable .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
}
.datatable .cellAction .viewButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
}
.datatable .cellAction .deleteButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
}
