.main {
    width: 60%;
    margin: 1rem auto;
    padding: 1rem;
    font-family: "Poppins", sans-serif;
}
.mainHeading {
    font-family: "Poppins", sans-serif;
    background-color: #d1000a;
    font-weight: 400;
    color: white;
    padding: 1rem;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
}
.mainHeading span {
    margin-right: 1rem;
}
.mailForm {
    gap: 1rem;
    padding: 2rem 3rem;
    background-color: rgba(112, 128, 144, 0.038);

}
.datePicker {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.5rem;
    font-family: "Poppins", sans-serif;
}
.aboutPet {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.buttonDiv {
    width: 100%;
    text-align: center;
}
.buttonDiv button {
    width: 100%;
    padding: 1rem;
    border: none;
    outline: none;
    background-color: #d1000a;
    color: white;
    font-size: large;
}
