@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.mainDiv {
    height: fit-content;
    width: 100vw;
    padding: 3rem 0;
}
.mainLine h1 {
    text-align: center;
    font-size: 70px;
    font-family: "Space Grotesk", sans-serif;
    color: #d1000a;
    font-weight: 700;
    letter-spacing: 1px;
}
.serviceIcons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-evenly;
    margin-top: 3rem;
    padding: 0px 15px;
}
.pair {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
}
.pair p {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: #d1000a;
    margin: 0;
}
.imagesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: fit-content;
    margin: 2rem auto;
    width: 80vw;
    gap: 1.2rem;
}
.imgContainer {
    position: relative;
    border-radius: 10px;
}
.imgContainer img {
    width: 100%;
    border-radius: 10px;
}
.imgContent {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    text-align: center;
    transition: all 0.3s ease-in-out 0.1s;
}
.imgContent h3 {
    color: #fff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    /* background-color: #d1000a9e; */
}

.imgContainer::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.67);
    opacity: 0;
    z-index: 1;
    transform: scaleY(0);
    transform-origin: 100% 100%;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}
.imgContainer:hover::after {
    opacity: 1;
    transform: scaleY(1);
}
.imgContainer:hover .imgContent {
    opacity: 1;
    top: 50%;
}
@media (max-width: 915px) {
    .mainLine h1 {
        font-size: 60px;
    }
}
@media (max-width: 800px) {
    .mainLine h1 {
        font-size: 40px;
    }
    .serviceIcons {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
    .imagesGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 540px) {
    .mainLine h1 {
        font-size: 25px;
    }
}

@media (max-width: 450px) {
    .serviceIcons {
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
    }
    .pair {
        gap: 5px;
    }
    .imagesGrid {
        grid-template-columns: 1fr;
    }
}
