@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500&display=swap");
.upper {
    width: 70%;
    margin: 0 auto;
    padding: 3rem 5rem;
    padding-top: 6rem;
    background-color: rgb(139, 0, 7);
    margin-top: 1rem;
}
.upper .info {
    display: flex;
    gap: 2rem;
}
.info .symbol {
    font-size: 36px !important;
    width: 110px !important;
    height: 110px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: slategray;
    background-color: #d8d8d8;
}
.info h5 {
    margin-top: auto;
    margin-bottom: 2.5rem;
    font-size: 28px;
    color: white;
}
.lower {
    width: 70%;
    margin: 0 auto;
}
.buttonsDiv {
    padding-left: 2rem;
}
.buttonsDiv button {
    padding: 0.5rem;
    border: none;
    outline: none;
    background-color: white;
    font-family: "Poppins", sans-serif;
}
.buttonsDiv button:hover {
    color: #d1000a;
}
.activeButton {
    color: #d1000a;
}
.buttonsDiv button:first-child {
    border-right: 1px solid #d1000a;
}
.profile {
    width: 70%;
    margin: 0 auto;
}
.profile .upperHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
}
.upperHeading h1 {
    font-size: 26px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.upperHeading button {
    border: 1px solid #d1000a;
    background-color: white;
    padding: 0.3rem 0.8rem;
    color: #d1000a;
}
.profileManagement {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 4rem;
}
.InputDiv {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 0.5rem;
}
.dropDownDiv {
    padding: 0.5rem 0.3rem;
    outline: none;
    width: 100%;
    background-color: white;
    color: black;
}

.InputDiv input {
    padding: 0.5rem 0.3rem;
    outline: none;
    border: 1px solid #d1000a;
}
.InputDiv select {
    padding: 0.5rem 0.3rem;
    outline: none;
    border: 1px solid #d1000a;
}
.InputDiv .dropDown {
    padding: 0.5rem 0.3rem;
    outline: none;
    border: 1px solid #d1000a;
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 0;
}
.dropDown:disabled {
    border: 1px solid slategray !important;
}

.InputDiv select:disabled {
    border: 1px solid slategray;
}
.InputDiv input:disabled,
.currentPassword input:disabled {
    border: 1px solid slategray;
}
.InputGroup {
    display: flex;
    gap: 15%;
    padding: 1.3rem 0;
}
.bottomButtonsDiv {
    display: flex;
    justify-content: center;
    padding: 1rem 3rem;
    margin-top: 2rem;
}
.bottomButtonsDiv button {
    border: 1px solid #d1000a;
    background-color: white;
    color: #d1000a;
    padding: 0.5rem 1rem;
}
.bottomButtonsDiv h1 {
    width: 100%;
    font-size: 26px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.currentPassword {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.currentPassword input {
    width: 40%;
    padding: 0.5rem 0.3rem;
    outline: none;
    border: 1px solid #d1000a;
}
.review {
    min-height: 70vh;
    width: 70%;
    margin: 0 auto;
}
.review .heading {
    margin: 0 auto;
    display: flex;
    padding: 1rem 3rem;
}
.heading h1 {
    font-size: 26px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.serviceDetails {
    padding: 1rem 2rem;
    margin-top: 1rem;
    background-color: rgba(101, 102, 104, 0.089);
    border: 1px solid rgba(112, 128, 144, 0.214);
    border-radius: 10px;
    padding-bottom: 3rem;
}
.serviceDetails h1 {
    font-family: "Poppins", sans-serif;
    font-size: 42px;
    font-weight: 400;
    text-transform: capitalize;
}
.servicesTable {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.servicesTable button {
    padding: 0.5rem;
    border: 1px solid gray;
}
.service {
    display: flex;
    padding: 2rem 1rem;
    justify-content: space-between;
}
.service p {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.805);
}
.service p:last-child {
    color: #d1000a;
    font-size: 1.7rem;
}
.third{
    color: #d1000a !important;
    font-size: 1.7rem;
}
.serviceAddButton {
    margin-top: 2rem;
    text-align: center;
}
.serviceAddButton button {
    padding: 1rem;
    font-weight: 500;
    color: red;
    border: 1px solid red;
    width: 60%;
}
.formModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.formDiv {
    width: 60vw;
    min-height: 60vh;
    background-color: white;
    border-radius: 7px;
    padding: 3rem 1rem;
}
.formDiv h4 {
    text-align: center;
}
.inputGroup {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    gap: 0.4rem;
    margin-top: 1rem;
}
.inputGroup select,
.inputGroup input {
    padding: 1rem;
}
.select {
    display: flex;
}
.select input {
    width: 100%;
}
.submitButton {
    width: 50%;
    text-align: center;
    margin: 0 auto;
    margin-top: 2rem;
}
.submitButton button {
    padding: 0.6rem;
    width: 100%;
    border: 1px solid slategray;
}
.closeModalButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
    background-color: transparent;
    border: none;
}
