@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.main {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}
.formDiv {
    height: fit-content;
    width: 50vw;
    margin-top: 3rem;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.323);
    border-radius: 10px;
    margin-bottom: 2rem;
}
.formDiv h1 {
    text-align: center;
    color: #035772;
    margin-top: 1rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
}
.formDiv form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 2rem auto;
    gap: 1rem;
}
.form input {
    padding: 1rem;
}
.input {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}
.select select {
    padding: 1rem;
    width: 29%;
    outline: none !important;
    height: 100%;
    border: 1px solid black;
    border-right: none;
}
.select input {
    padding: 1rem;
    width: 70%;
    height: 100%;
    border: 1px solid black;
}
