.upperContainer {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 4rem;
}
.upperIntro {
    display: flex;
    gap: 6rem;
    padding: 2rem 2rem;
    margin: 0 auto;
    background-color: rgb(139, 0, 7);
}
.serviceDetails {
    padding: 1rem 2rem;
    margin-top: 1rem;
    background-color: rgba(101, 102, 104, 0.089);
    border: 1px solid rgba(112, 128, 144, 0.214);
    border-radius: 10px;
    padding-bottom: 3rem;
}
.serviceDetails h1 {
    font-family: "Poppins", sans-serif;
    font-size: 42px;
    font-weight: 400;
    text-transform: capitalize;
}
.servicesTable {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.service {
    display: flex;
    padding: 2rem 1rem;
    justify-content: space-between;
}
.service p {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.805);
}
.service p:last-child {
    color: #d1000a;
    font-size: 1.7rem;
}
.imageDiv img {
    border-radius: 100%;
    border: 2px solid white;
}
.infoDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.infoDiv h1 {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 500;
    text-transform: capitalize;
    color: white;
}
.infoDiv p {
    text-transform: capitalize;
    color: rgb(101, 102, 104);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: white;
}
.buttonDiv {
    margin-left: auto;
    align-self: flex-end;
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}
.buttonDiv button {
    padding: 0.9rem 2rem;
    border: none;
    border-radius: 100px;
    background-color: white;
    color: #d1000a;
    font-weight: 500;
}
.mapouter {
    margin-top: 1rem;
    border-radius: 9px;
    position: relative;
    text-align: right;
    width: 100%;
    height: fit-content;
    background-color: rgba(101, 102, 104, 0.089);
    border: 1px solid rgba(112, 128, 144, 0.226);
}
.mapouter h1 {
    text-align: left;
    padding: 1rem 2rem;
    font-family: "Poppins", sans-serif;
    font-size: 42px;
    font-weight: 400;
    text-transform: capitalize;
    color: rgb(37, 38, 39);
}
.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 70vh;
}
.gmap_iframe {
    height: 100vh !important;
}