.sidebar {
    flex: 1;
    border-right: 0.5px solid #e6e3e3;
    min-height: 100vh;
    background-color: white;
}
.sidebar .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebar .top .logo {
    font-size: 40px;
    font-weight: bold;
    color: #36ffbc;
}
.sidebar hr {
    height: 0;
    border: 0.5px solid #e6e3e3;
}
.sidebar .center {
    padding-left: 10px;
}
.sidebar .center ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.sidebar .center ul .title {
    font-size: 15px;
    font-weight: bold;
    color: #999;
    margin-top: 15px;
    margin-bottom: 5px;
}
.sidebar .center ul li {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}
.sidebar .center ul li:hover {
    background-color: #ece8ff;
}
.sidebar .center ul li .icon {
    font-size: 30px;
    color: #36ffbc;
}
.sidebar .center ul li span {
    font-size: 15px;
    font-weight: 600;
    color: #888;
    margin-left: 10px;
}
.sidebar .bottom {
    display: flex;
    align-items: center;
    margin: 10px;
}
.sidebar .bottom .colorOption {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #36ffbc;
    cursor: pointer;
    margin: 5px;
}
.sidebar .bottom .colorOption:nth-child(1) {
    background-color: whitesmoke;
}
.sidebar .bottom .colorOption:nth-child(2) {
    background-color: #333;
}
