.update-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.update-image__label {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
}
.update-image__image {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid gray;
    margin-bottom: 2rem;
}
.update-image__image-icon {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid gray;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: white;
    font-size: xx-large;
    justify-content: center;
}
.button-secondary {
    padding: 0.5rem 2rem;
    border: none;
    background-color: white;
}
