@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@700&family=Space+Grotesk&display=swap");
.main {
}
.heading h1 {
    font-family: "Edu NSW ACT Foundation", cursive;
    padding-top: 2rem;
    color: #d1000a;
}
.heading h3 {
    font-family: "Edu NSW ACT Foundation", cursive;
    padding-top: 2rem;
    color: #d1000a;
    margin-top: 1rem;
}
.contentWrapper {
    width: 80%;
    margin: 0 auto;
}
.content {
    top: -60px;
    position: relative;
    background-color: white;
}
.bgImage {
    height: 400px;
    background-image: url("../../assets/images/AboutUSHero.jpg");
    background-repeat: no-repeat;
    background-position: center 32%;
    background-size: cover !important;
}
.leftblock {
    width: 80%;
    margin: 0 auto;
    margin-top: 2.3rem;
}
.leftblock p {
    font-family: "Poppins", sans-serif !important;
    line-height: 2;
    color: #d1000a;
}
.endingLine h3 {
    margin-top: 2rem;
    font-family: "Edu NSW ACT Foundation", cursive;
    color: #d1000a;
    text-align: center;
    margin-bottom: 3rem;
}

p span {
    font-size: larger;
    font-weight: 600;
}
