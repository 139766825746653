@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&family=Space+Grotesk&display=swap");
.main {
    height: 60vh;
    position: relative;
    width: 100vw;
    background-color: rgb(70, 0, 3);
}

.above {
    display: flex;
    align-items: center;
    height: 40vh;
    justify-content: space-evenly;
}
.left,
.right {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.below {
    position: absolute;
    bottom: 2px;
    left: 46%;
    color: #fff;
    font: 20px;
    font-family: "Poppins", sans-serif;
}
@media (max-width: 450px) {
    .above {
        flex-direction: column;
    }
}
