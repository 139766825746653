.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found-image {
  max-width: 100%;
  height: 100vh;
  width: 100vw;
}

.not-found-text {
  font-size: 24px;
}

